import { memo, useCallback, useState, useMemo, Fragment, useEffect, useRef } from 'react';
import { Row } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { CampaignSovTable, PageAuthTable, CampaignLinkBuildingTable, CampaignKeywordRankingsTable, KeywordDistributionTable } from '@components/campaigns/view/tables';
import { CampaignSOVChart, CampaignLinksChart, PageAuthGraph, CampaignKeywordRankingChart, KeywordDistributionGraph } from '@components/campaigns/view/graphs';
import { useGetByIdQuery, useUpdateMutation, useUpdateConfigMutation } from '@components/campaigns/campaignsApi';
import { List, arrayMove } from 'react-movable';
import { getFiltersFor, constants } from '@utils';
import QEditor from '@elements/QEditor/QEditor';
import { useParams } from 'react-router-dom';
import SummaryAndFindings from '@components/SummaryAndFindings';
import { showError, showSuccess } from '@components/Notifications';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useDataConfig } from '@hooks/dataConfig';

const ChainChildren = ({ main, children, hidden }) => {
  const [showChildren, setShowChildren] = useState(false);

  if (hidden) {
    return <Fragment>{children}</Fragment>;
  }

  if (!showChildren) {
    return main({ showChildren: () => setShowChildren(true) });
  }

  return (
    <Fragment>
      {main({ showChildren: () => setShowChildren(true) })}
      {children}
    </Fragment>
  );
};

const NoPriorityKeywords = ({ onLoadEnd, chartWidth }) => {
  useEffect(() => {
    onLoadEnd && onLoadEnd();
  }, [onLoadEnd]);

  return (
    <div style={{ flexGrow: 1, maxWidth: chartWidth, width: chartWidth }} className="position-relative mx-auto">
      <h5 className="text-center fs-6 pt-3 pb-2">Priority Keywords</h5>
      <div className="border text-center" style={{ padding: '8rem 1rem' }}>
        No Priority Keywords selected.
      </div>
    </div>
  );
};

const SegmentationChain = ({ slug, maxUrls, chartWidth }) => {
  const { id } = useParams();
  const dataConfig = useDataConfig(id);
  const { data } = useGetByIdQuery({ id, params: { max_urls: maxUrls } });
  const [updateCampaign, updateCampaignResult] = useUpdateMutation();

  const noFiltersEnabled = useMemo(() => {
    return Object.entries(dataConfig.filters).filter(i => i[1]).length === 0;
  }, [dataConfig]);

  const updateGraphKey = useCallback(
    (content, key) => {
      const updateObject = {
        id,
        graph_data: {
          [key]: {
            description: content,
          },
        },
      };
      return updateCampaign(updateObject).unwrap();
    },
    [updateCampaign, id]
  );

  const chartProps = useMemo(
    () => ({
      campaignId: id,
      updateContent: updateGraphKey,
      graph_data: data ? data.campaign.graph_data : {},
      isSaving: updateCampaignResult.isLoading,
    }),
    [id, updateGraphKey, data, updateCampaignResult]
  );

  const isFilterEnabled = useCallback(
    (...filters) => {
      for (const filter of filters) {
        if (!dataConfig.filters[filter]) {
          return false;
        }
      }
      return true;
    },
    [dataConfig]
  );

  const order = useMemo(() => (dataConfig.order && dataConfig.order[slug] ? dataConfig.order[slug] : []), [dataConfig, slug]);

  const Segments = useMemo(() => {
    const isSegmentationVisible = (metric, segmentation) => {
      if (!dataConfig.pdf_export[metric]) {
        return false;
      }

      if (!dataConfig.hide_non_selected) {
        return true;
      }

      return dataConfig.pdf_export[metric].includes(segmentation);
    };

    return constants.FILTERS.sort((a, b) => {
      if (order.indexOf(a.slug) !== -1 || order.indexOf(b.slug) !== -1) {
        return order.indexOf(a.slug) - order.indexOf(b.slug);
      }

      const filters = constants.FILTERS.map(i => i.slug);

      return filters.indexOf(a.slug) - filters.indexOf(b.slug);
    })
      .reverse()
      .reduce((acc, i) => {
        const showTable = slug === 'all_tracked' && !noFiltersEnabled;

        const MainComponent = ({ showChildren }) => {
          if (i.slug === 'priority' && getFiltersFor(i.slug, dataConfig).priority.length === 0) {
            return <NoPriorityKeywords onLoadEnd={showChildren} chartWidth={chartWidth} />;
          }

          if (slug === 'keyword_rankings') {
            return <CampaignKeywordRankingChart {...chartProps} width={chartWidth} showTable={!noFiltersEnabled} segmentKey={i.slug} title={i.text} onLoadEnd={showChildren} />;
          }

          if (slug === 'keyword_distribution') {
            return <KeywordDistributionGraph {...chartProps} width={chartWidth} showTable={!noFiltersEnabled} segmentKey={i.slug} title={i.text} onLoadEnd={showChildren} />;
          }

          return <CampaignSOVChart {...chartProps} width={chartWidth} showTable={!noFiltersEnabled} segmentKey={i.slug} title={i.text} onLoadEnd={showChildren} />;
        };

        const Output = () => {
          const hidden = !((isFilterEnabled(i.slug) || showTable) && isSegmentationVisible(slug, i.slug));

          return (
            <ChainChildren hidden={hidden} main={MainComponent}>
              {acc}
            </ChainChildren>
          );
        };

        return <Output />;
      }, <Fragment></Fragment>);
  }, [slug, chartProps, chartWidth, dataConfig, isFilterEnabled, noFiltersEnabled, order]);

  return Segments;
};

const ReorderButton = ({ segment }) => {
  const { id } = useParams();
  const dataConfig = useDataConfig(id);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState(constants.FILTERS);
  const [updateConfig, updateConfigStatus] = useUpdateConfigMutation();
  const containerRef = useRef();

  useEffect(() => {
    if (!dataConfig || !dataConfig.order || !dataConfig.order[segment]) {
      return false;
    }

    const filters = dataConfig.order[segment].map(slug => constants.FILTERS.find(i => i.slug === slug) || { slug, text: '?' });

    for (const filter of constants.FILTERS) {
      if (!filters.find(i => i.slug === filter.slug)) {
        filters.push(filter);
      }
    }

    setItems(filters);
  }, [dataConfig, segment]);

  const toggleOpenDropdownSlug = e => {
    e.stopPropagation();
    setIsOpen(i => !i);
  };

  const handleOrderChange = async ({ oldIndex, newIndex }) => {
    const output = arrayMove(items, oldIndex, newIndex);

    setItems(output);

    const { error } = await updateConfig({ campaign: { id }, config: { order: { ...dataConfig.order, [segment]: output.map(i => i.slug) } } });

    if (error) {
      setItems(items);
    }
  };

  return (
    <div className="d-flex justify-content-end">
      <div>
        <div onClick={toggleOpenDropdownSlug} className="d-flex align-items-center bg-gray px-2 rounded cursor-pointer justify-content-center" style={{ minWidth: '220px' }}>
          <i className="material-icons fs-3" style={{ position: 'unset' }}>
            {isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
          </i>
          <p className="mb-0 mt-0 fs-8 fw-bold py-2 px-4 text-center">Reorder</p>
        </div>
        {isOpen && (
          <div className="text-nowrap w-100 pl-3">
            <div ref={containerRef}>
              <List
                values={items.map(({ text }) => text)}
                disabled={updateConfigStatus.isLoading || updateConfigStatus.isFetching}
                container={containerRef.current}
                onChange={handleOrderChange}
                renderList={({ children, props }) => <div {...props}>{children}</div>}
                renderItem={({ value, props, isDragged }) => {
                  const index = items.map(i => i.text).indexOf(value);

                  return (
                    <div {...props}>
                      <div className="my-2 d-flex align-items-center">
                        <span>{index === -1 ? '?' : index + 1}.</span>
                        <div data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'pointer' }}>
                          <i className="material-icons fs-6 ml-1f" style={{ padding: '3px 0' }}>
                            more_vert
                          </i>
                        </div>
                        <p className="mx-2 my-0">{value}</p>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const AccordionComponent = ({ maxUrls }) => {
  const { id } = useParams();
  const dataConfig = useDataConfig(id);
  const [updateConfig, updateConfigStatus] = useUpdateConfigMutation();
  const [updateCampaign, updateCampaignResult] = useUpdateMutation();
  const { data } = useGetByIdQuery({ id, params: { max_urls: maxUrls } });
  const [openItems, setOpenItems] = useState([]);
  const [sovTableReady, setSovTableReady] = useState(false);
  const [keywordDistributionTableReady, setKeywordDistributionTableReady] = useState(false);
  const [keywordRankingsTableReady, setKeywordRankingsTableReady] = useState(false);

  const updateGraphKey = useCallback(
    (content, key) => {
      const updateObject = {
        id,
        graph_data: {
          [key]: {
            description: content,
          },
        },
      };
      return updateCampaign(updateObject).unwrap();
    },
    [updateCampaign, id]
  );

  const chartProps = {
    campaignId: id,
    updateContent: updateGraphKey,
    graph_data: data ? data.campaign.graph_data : {},
    isSaving: updateCampaignResult.isLoading,
  };

  const chartWidth = useMemo(() => {
    const enabledFiltersCount = Object.entries(dataConfig.filters).filter(i => i[1]).length;

    if (enabledFiltersCount < 2) {
      return 720;
    }

    return 480;
  }, [dataConfig]);

  const summaryAndFindingsInitialContent = useMemo(() => {
    const { keyword_rankings_modal_comments, link_building_modal_comments, page_authority_modal_comments, share_of_voice_modal_comments, general = '' } = dataConfig.report;

    return {
      general,
      keyword_ranking: keyword_rankings_modal_comments || '',
      link_building: link_building_modal_comments || '',
      page_authority: page_authority_modal_comments || '',
      share_of_voice: share_of_voice_modal_comments || '',
    };
  }, [dataConfig.report]);

  const handleSummaryAndFindingsSave = async (content, key) => {
    const formatKeyName = key => {
      if (key === 'general') {
        return key;
      }
      if (key === 'keyword_ranking') {
        return `${key}s_modal_comments`;
      }
      return `${key}_modal_comments`;
    };

    const { error } = await updateConfig({
      campaign: { id },
      config: {
        report: {
          ...dataConfig.report,
          [formatKeyName(key)]: content,
        },
      },
    });

    if (error) {
      return showError('Error saving!');
    }

    showSuccess('Saved');
  };

  return (
    <div>
      <Accordion allowMultipleExpanded allowZeroExpanded onChange={setOpenItems}>
        <AccordionItem uuid="6">
          <AccordionItemHeading>
            <AccordionItemButton>Summary & Findings</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {openItems.includes('6') && (
              <Row>
                <SummaryAndFindings initialContent={summaryAndFindingsInitialContent} onSave={handleSummaryAndFindingsSave} isSaving={updateConfigStatus.isLoading} />
              </Row>
            )}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="1">
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'inline-flex', alignItems: 'center', width: 'calc(100% - 6rem)', justifyContent: 'space-between' }}>
                <p className="mb-0">Share of Voice</p>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {openItems.includes('1') && (
              <Fragment>
                <ReorderButton segment="share_of_voice" />
                {sovTableReady && (
                  <Row>
                    <div className="w-100">
                      <div className="d-flex justify-content-center">
                        <div className="w-100 mw-100">
                          <QEditor
                            isSaving={updateCampaignResult.isLoading}
                            initialContent={chartProps.graph_data?.sov_graph?.description || ''}
                            updateContent={content => updateGraphKey(content, 'sov_graph')}
                            className="mb-0"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-wrap w-100">
                        <SegmentationChain slug="share_of_voice" maxUrls={maxUrls} chartWidth={chartWidth} />
                      </div>
                    </div>
                  </Row>
                )}
                <Row>
                  <CampaignSovTable onLoadEnd={() => setSovTableReady(true)} campaignId={id} />
                </Row>
              </Fragment>
            )}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="2">
          <AccordionItemHeading>
            <AccordionItemButton>Page Authority</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {openItems.includes('2') && (
              <>
                <Row>
                  <PageAuthGraph {...chartProps} />
                </Row>
                <Row>
                  <PageAuthTable campaignId={id} />
                </Row>
              </>
            )}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="3">
          <AccordionItemHeading>
            <AccordionItemButton>Link Building</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {openItems.includes('3') && (
              <>
                <Row>
                  <CampaignLinksChart {...chartProps} />
                </Row>
                <Row>
                  <CampaignLinkBuildingTable campaignId={id} />
                </Row>
              </>
            )}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="4">
          <AccordionItemHeading>
            <AccordionItemButton>Keyword Rankings</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {openItems.includes('4') && (
              <Fragment>
                <ReorderButton segment="keyword_rankings" />
                {keywordRankingsTableReady && (
                  <Row>
                    <div className="w-100">
                      <div className="d-flex justify-content-center">
                        <div className="w-100 mw-100">
                          <QEditor
                            isSaving={updateCampaignResult.isLoading}
                            initialContent={chartProps.graph_data?.keyword_rankings?.description || ''}
                            updateContent={content => updateGraphKey(content, 'keyword_rankings')}
                            className="mb-0"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-wrap w-100">
                        <SegmentationChain slug="keyword_rankings" maxUrls={maxUrls} chartWidth={chartWidth} />
                      </div>
                    </div>
                  </Row>
                )}
                <Row>
                  <CampaignKeywordRankingsTable onLoadEnd={() => setKeywordRankingsTableReady(true)} campaignId={id} />
                </Row>
              </Fragment>
            )}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="5">
          <AccordionItemHeading>
            <AccordionItemButton>Keyword Distribution</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {openItems.includes('5') && (
              <Fragment>
                <ReorderButton segment="keyword_distribution" />
                {keywordDistributionTableReady && (
                  <Row>
                    <div className="w-100">
                      <div className="d-flex justify-content-center">
                        <div className="w-100 mw-100">
                          <QEditor
                            isSaving={updateCampaignResult.isLoading}
                            initialContent={chartProps.graph_data?.keyword_distribution?.description || ''}
                            updateContent={content => updateGraphKey(content, 'keyword_distribution')}
                            className="mb-0"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-wrap w-100">
                        <SegmentationChain slug="keyword_distribution" maxUrls={maxUrls} chartWidth={chartWidth} />
                      </div>
                    </div>
                    <p className="mx-1 mt-4">
                      Reviewing ranking distribution by URL shows pages that are ranking comfortably, vs. those that are do not have high visibility and may present opportunities
                      for the campaign.
                    </p>
                  </Row>
                )}
                <Row>
                  <KeywordDistributionTable onLoadEnd={() => setKeywordDistributionTableReady(true)} campaignId={id} />
                </Row>
              </Fragment>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default memo(AccordionComponent);
